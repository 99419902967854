@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #000;
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 100px;
}

@media only screen and (max-width: 830px) {
  #contents_wrap {
    margin-bottom: 50px;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 830px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 831px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  max-width: 100% !important;
  float: none !important;
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-family: 'Noto Sans Japanese';
}

@media only screen and (max-width: 830px) {
  header#global_header {
    padding-top: 48px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

#header {
  width: 100%;
}

#header #siteID {
  width: 19%;
}

@media only screen and (max-width: 830px) {
  #header #siteID {
    padding-bottom: 10px;
    width: 100%;
  }
}

#header #siteID a {
  opacity: 1;
}

#header #nav_global {
  width: 100%;
  padding: 20px 0;
  background: #67bc77;
}

#header #nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

@media screen and (min-width: 831px) and (max-width: 1200px) {
  #header #nav_global ul {
    padding: 0 10px;
  }
}

#header #nav_global ul li {
  position: relative;
}

#header #nav_global ul li a {
  font-weight: 400;
  color: #fff;
  display: block;
  padding: 15px 0;
}

#header #nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#header #nav_global ul ul {
  width: 215px;
  position: absolute;
  z-index: 100;
  top: 100%;
  left: -155%;
  display: block;
  background: rgba(255, 255, 255, 0.7);
}

#header #nav_global ul ul li {
  position: relative;
}

#header #nav_global ul ul li a {
  color: #008000;
  padding: 10px;
  display: block;
}

#header #hed_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 0;
}

@media only screen and (max-width: 830px) {
  #header #hed_info {
    display: block;
  }
}

#header .tel2 {
  width: 33%;
  font-size: 50px;
  font-weight: 700;
  color: #67bc77;
  line-height: 1;
}

@media only screen and (max-width: 640px) {
  #header .tel2 {
    width: 100%;
    padding-top: 10px;
    line-height: 0;
  }
  #header .tel2 a {
    color: #67bc77;
    font-size: 30px;
  }
  #header .tel2 span {
    line-height: 1.3;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header .tel2 {
    width: 100%;
    padding-top: 10px;
  }
  #header .tel2 a {
    color: #67bc77;
    font-size: 30px;
  }
}

@media screen and (min-width: 831px) and (max-width: 1000px) {
  #header .tel2 {
    font-size: 34px;
  }
  #header .tel2 a {
    color: #67bc77;
  }
}

@media screen and (min-width: 831px) and (max-width: 1200px) {
  #header .tel2 {
    font-size: 34px;
  }
  #header .tel2 a {
    color: #67bc77;
  }
}

#header .tel2 img {
  margin-right: 7px;
  width: 11%;
}

@media only screen and (max-width: 640px) {
  #header .tel2 img {
    width: auto;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header .tel2 img {
    width: 8%;
  }
}

#header .cont {
  font-size: 20px;
}

#header .cont p {
  padding: 16px 32px;
  background: #b94047;
}

#header .cont a {
  color: #fff;
}

.time {
  width: 29%;
  line-height: 2;
}

@media only screen and (max-width: 830px) {
  .time {
    width: 65%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 831px) and (max-width: 1000px) {
  .time {
    width: 40%;
  }
}

@media screen and (min-width: 831px) and (max-width: 1200px) {
  .time {
    width: 40%;
  }
}

.time .time_top {
  background: #008000;
  color: #fff;
  font-size: 18px;
}

.time .heijitsu {
  text-align: left;
}

.time .holiday {
  text-align: left;
}

#mainArea {
  width: 100%;
}

#mainArea img {
  width: 100%;
}

@media only screen and (max-width: 830px) {
  #mainArea img {
    width: 145% !important;
    height: 350px !important;
    object-fit: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #mainArea img {
    width: 145% !important;
    height: 600px !important;
    object-fit: cover;
  }
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: left;
  text-shadow: 5px 5px 10px rgba(0, 100, 0, 0.4);
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
  }
  #local-keyvisual h1 span {
    padding: 50px 0 !important;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 150px 0;
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: bottom;
  background-size: cover;
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.mean-container .mean-bar {
  background: #67bc77 !important;
  position: fixed !important;
  z-index: 8 !important;
}

.mean-container .mean-nav {
  background: #67bc77 !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li a {
  padding: 15px 5% !important;
}

.mean-container .mean-nav ul li a i {
  margin-right: 15px;
  font-size: 16px;
}

.mean-container a.meanmenu-reveal span {
  background: #fff !important;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #3f280f;
  margin-top: 0;
  font-style: normal;
  font-weight: bold;
}

footer#global_footer a {
  color: #3f280f;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: underline;
}

footer#global_footer .nav_L {
  float: left;
  text-align: left;
  width: 75%;
  margin-top: 40px;
}

@media only screen and (max-width: 830px) {
  footer#global_footer .nav_L {
    float: none;
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 831px) and (max-width: 1000px) {
  footer#global_footer .nav_L {
    width: 60%;
  }
}

footer#global_footer #nav_footer {
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 5px;
  display: block;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #nav_footer .col_L {
  float: left;
  text-align: left;
  width: 15%;
}

@media screen and (min-width: 831px) and (max-width: 1000px) {
  footer#global_footer #nav_footer .col_L {
    width: 20%;
  }
}

footer#global_footer #nav_footer .col_R {
  float: right;
  text-align: left;
  width: 10%;
}

@media screen and (min-width: 831px) and (max-width: 1000px) {
  footer#global_footer #nav_footer .col_R {
    width: 20%;
  }
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

footer#global_footer .small {
  background: #67bc77;
  color: #fff;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.h2_01 {
  color: #67bc77;
  font-size: 30px;
  line-height: 1.5;
}

@media only screen and (max-width: 640px) {
  .h2_01 {
    font-size: 24px;
  }
}

.h2_02 {
  font-size: 30px;
}

@media only screen and (max-width: 640px) {
  .h2_02 {
    font-size: 24px;
  }
}

.h2_02 img {
  margin-right: 7px;
}

.insta {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.insta .sns_text {
  display: none;
}

.insta .sns_list {
  display: flex;
}

.insta div.sns_list {
  border: none;
}

.insta div.sns_list > div {
  border-bottom: none;
}

.insta div.sns_list {
  flex-direction: unset !important;
}

@media only screen and (max-width: 640px) {
  .insta div.sns_list {
    flex-direction: column !important;
  }
}

.insta div.sns_list div.sns_photo {
  width: 100%;
}

.sec_01 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

@media only screen and (max-width: 830px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .flxL {
    width: 100% !important;
  }
  .sec_01 .flxR {
    width: 100% !important;
  }
  .sec_01 img {
    width: 100% !important;
  }
}

.sec_01 .flxL {
  width: 47%;
}

.sec_01 .flxR {
  width: 51%;
}

.sec_02 {
  justify-content: space-between;
}

.sec_02 .box {
  width: 23%;
}

@media only screen and (max-width: 830px) {
  .sec_02 .box {
    width: 49%;
  }
}

.sec_02 .box img {
  width: 100% !important;
}

.sec_02 .box h3 {
  background: #67bc77;
  color: #fff;
  text-align: center;
  font-size: 20px;
  padding: 6px;
}

.sec_02 .box article > div {
  text-align: center !important;
  margin-top: 15px;
}

@media only screen and (max-width: 640px) {
  .sec_02 .box article > div {
    margin-top: 0;
    padding: 10px;
  }
}

.newswrap {
  background: rgba(255, 255, 255, 0.5);
  padding: 50px;
}

@media only screen and (max-width: 640px) {
  .newswrap {
    padding: 20px;
  }
}

.news {
  height: 300px;
  overflow-y: scroll;
}

.news dt {
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .news dt {
    width: 100% !important;
  }
}

.news dd {
  border: none !important;
  padding: 25px !important;
}

@media only screen and (max-width: 640px) {
  .news dd {
    width: 100% !important;
    padding: 30px 8px !important;
  }
}

@media only screen and (max-width: 640px) {
  .news dl {
    display: block !important;
  }
}

.txt2 {
  width: 700px;
  margin: 0 auto;
  padding: 10px 0;
}

.table_01 {
  max-width: 700px;
  margin: 0 auto;
}

.table_02 {
  width: 450px;
  margin: 0 auto;
}

.table_02 dt {
  border: none !important;
  align-items: flex-start !important;
  display: block !important;
  text-align: center;
  padding: 0 !important;
}

.table_02 dd {
  border: none !important;
  padding: 0 !important;
}

.map iframe {
  width: 100%;
}

/* box */
.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.h2_03 h2 {
  padding: 0.4em 0.5em;
  /*文字の上下 左右の余白*/
  color: #494949;
  /*文字色*/
  background: #f4f4f4;
  /*背景色*/
  border-left: solid 5px #67bc77;
  /*左線*/
  border-bottom: solid 3px #d7d7d7;
  /*下線*/
  font-size: 30px;
  line-height: 1.5;
}

@media only screen and (max-width: 640px) {
  .h2_03 h2 {
    font-size: 24px;
  }
}

.h2_04 h2 {
  padding: 0.4em 0.5em;
  /*文字の上下 左右の余白*/
  color: #494949;
  /*文字色*/
  background: #f4f4f4;
  /*背景色*/
  border-left: solid 5px #67bc77;
  /*左線*/
  border-bottom: solid 3px #d7d7d7;
  /*下線*/
  font-size: 30px;
  line-height: 1.5;
}

@media only screen and (max-width: 640px) {
  .h2_04 h2 {
    font-size: 17px;
  }
}

.h3_01 img {
  margin-right: 7px;
}

.h3_01 h3 {
  font-weight: bold;
  font-size: 20px;
  color: #008000;
}

@media only screen and (max-width: 640px) {
  .h3_01 h3 {
    font-size: 18px;
  }
}

.txt3 {
  border: 1px solid #67bc77;
  padding: 20px;
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .img {
    width: 100% !important;
  }
  .sec_03 .txt {
    width: 100% !important;
  }
}

.sec_03 .img {
  width: 40%;
}

.sec_03 .txt {
  width: 53%;
}

.quewrap {
  border-bottom: 1px dotted #67bc77;
  padding-bottom: 20px;
}

.quewrap .question > div:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #008000;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  margin-right: 7px;
}

.quewrap .answer > div:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #67bc77;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  margin-right: 7px;
}

.table_03 dt {
  text-align: center;
  display: block !important;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  .table_03 dt {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .table_03 dd {
    width: 100% !important;
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  .table_03 dl {
    display: block !important;
  }
}

.table_04 dt {
  text-align: center;
  display: block !important;
  background: #67bc77 !important;
  align-items: center !important;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  .table_04 dt {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .table_04 dl {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .table_04 dd {
    width: 100% !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 80px 0 !important;
}

@media only screen and (max-width: 830px) {
  .bg_100per_wrap {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #ebfed8;
  background-size: cover;
  z-index: -1;
}

.bg_100per_wrap2 {
  position: relative;
  padding: 80px 0 !important;
}

@media only screen and (max-width: 830px) {
  .bg_100per_wrap2 {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/news_img.jpg);
  background-size: cover;
  z-index: -1;
}

.bg_100per_wrap3 {
  position: relative;
  padding: 70px 0 !important;
}

@media only screen and (max-width: 830px) {
  .bg_100per_wrap3 {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner3 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #ebfed8;
  background-size: cover;
  z-index: -1;
}
